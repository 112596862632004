/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import { Loading } from '~/components'

const Images = ['div', 'li'].reduce((obj, key) => {
  obj[key] = styled[key]`
    ${(props) =>
      props.position &&
      css`
        position: ${props.position};
      `}
    ${(props) =>
      props.src &&
      css`
        background: url(${props.src}) center / cover;
      `}
  `
  return obj
}, {})

Images.img = forwardRef(function LazyImg(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { src, children, position, ...props },
  ref
) {
  return <img ref={ref} src={src} {...props} />
})

const Lazyload = ({ src, children, type = 'div', ...props }) => {
  const Image = Images[type]

  return (
    <LazyImageFull src={src}>
      {({ imageState, ref }) => (
        <Image
          ref={ref}
          src={imageState === ImageState.LoadSuccess ? src : null}
          position="relative"
          {...props}
        >
          {imageState !== ImageState.LoadSuccess ? (
            <Loading active absolute />
          ) : (
            children
          )}
        </Image>
      )}
    </LazyImageFull>
  )
}

export default Lazyload
