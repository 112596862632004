import axios from 'axios'
const api = axios.create()
const requestData = (method, path, data, config = {}) => {
  const apiUrl = `https://www.rdstation.com.br/api/1.3/${path}`
  const promise = api[method](apiUrl, data, config)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        return error.response.data
      }
      return { errors: {} }
    })
  return promise
}
export default requestData
